define("shared/utils/download-files", ["exports", "file-saver"], function (_exports, _fileSaver) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.downloadFile = downloadFile;

  function downloadFile(fileName, content, contentType = 'text/plain;charset=utf-8') {
    const blob = new Blob([content], {
      type: contentType
    });
    (0, _fileSaver.saveAs)(blob, fileName);
  }
});