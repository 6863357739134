define("shared/components/cru-cloud-provider/component", ["exports", "shared/components/cru-cloud-provider/template", "ui/utils/constants", "shared/components/cru-cloud-provider/cloud-provider-info", "ui/utils/debounce", "semver"], function (_exports, _template, _constants, _cloudProviderInfo, _debounce, _semver) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const azureDefaults = _constants.default.AZURE_DEFAULTS;
  const GENERIC_PATH = 'cluster.rancherKubernetesEngineConfig.cloudProvider.cloudConfig';
  const AWS_PATH = 'cluster.rancherKubernetesEngineConfig.cloudProvider.awsCloudProvider';
  const AZURE_PATH = 'cluster.rancherKubernetesEngineConfig.cloudProvider.azureCloudProvider';
  const AZURE_IN_TREE_MINIMUM_VERSION = 'v1.27.0';
  const AZURE_IN_TREE_DEPRICATE_VERSION = 'v1.30.0';

  var _default = Ember.Component.extend({
    globalStore: Ember.inject.service(),
    settings: Ember.inject.service(),
    growl: Ember.inject.service(),
    layout: _template.default,
    configType: null,
    cluster: null,
    driver: null,
    selectedCloudProvider: 'none',
    mode: 'new',
    hasBuiltIn: false,
    configAnswers: null,
    clusterTemplateCreate: false,
    configVariable: null,
    questions: null,
    azureDefaults,
    azureDescriptions: _cloudProviderInfo.azure,
    unsupportedProviderSelected: false,
    showChangedToAmazonExternal: false,
    // track the original configuration to revert the switch to 'external' when the selected provider is not supported
    initialCloudProvider: null,
    initialConfigAnswers: null,
    showChangedToExternal: false,
    configName: Ember.computed.alias('cluster.rancherKubernetesEngineConfig.cloudProvider.name'),

    init() {
      this._super(...arguments);

      const cloudProviderName = Ember.get(this, 'cluster.rancherKubernetesEngineConfig.cloudProvider.name');

      if (cloudProviderName === 'aws') {
        Ember.setProperties(this, {
          selectedCloudProvider: 'amazonec2',
          configAnswers: Ember.get(this, AWS_PATH),
          initialCloudProvider: 'amazonec2',
          initialConfigAnswers: Ember.get(this, AWS_PATH)
        });
      } else if (cloudProviderName === 'azure') {
        const reorderedAnswers = this.sortAzureFields(this.globalStore.getById('schema', 'azurecloudprovider'), Ember.get(this, AZURE_PATH));
        this.setCpFields(`azureCloudProvider`, reorderedAnswers);
        const shouldAllowEditingCloudProvider = this.mode === 'edit' && this.isAzureInTreeSupported;
        Ember.setProperties(this, {
          selectedCloudProvider: 'azure',
          configAnswers: reorderedAnswers,
          initialCloudProvider: 'azure',
          initialConfigAnswers: reorderedAnswers,
          unsupportedProviderSelected: shouldAllowEditingCloudProvider
        });
      } else if (!cloudProviderName) {
        Ember.set(this, 'selectedCloudProvider', 'none');
      } else {
        Ember.setProperties(this, {
          selectedCloudProvider: cloudProviderName ? cloudProviderName : 'generic',
          configAnswers: Ember.get(this, GENERIC_PATH),
          initialCloudProvider: cloudProviderName ? cloudProviderName : 'generic',
          initialConfigAnswers: Ember.get(this, GENERIC_PATH)
        });
      }
    },

    driverDidChange: Ember.observer('driver', function () {
      Ember.set(this, 'selectedCloudProvider', 'none');
    }),
    modeChanged: Ember.observer('selectedCloudProvider', function () {
      let selectedCloudProvider = Ember.get(this, 'selectedCloudProvider');
      const showChangedToExternal = Ember.get(this, 'showChangedToExternal');

      if (selectedCloudProvider !== 'none') {
        this.constructConfig();
      } else {
        let config = Ember.get(this, 'cluster.rancherKubernetesEngineConfig');

        if (config && Ember.get(config, 'cloudProvider')) {
          delete config.cloudProvider;
        }
      } // remove azure warning banner when selection changes


      if (selectedCloudProvider !== 'external' && showChangedToExternal) {
        Ember.set(this, 'showChangedToExternal', false);
      }
    }),
    harvesterCloudProviderDisabledChange: Ember.observer('harvesterCloudProviderDisabled', function () {
      if (Ember.get(this, 'harvesterCloudProviderDisabled')) {
        Ember.set(this, 'selectedCloudProvider', 'none');
      }
    }),
    k8sVersionDidChange: Ember.observer('cluster.rancherKubernetesEngineConfig.kubernetesVersion', function () {
      const kubernetesVersion = Ember.get(this, 'cluster.rancherKubernetesEngineConfig.kubernetesVersion');
      const initialCloudProvider = Ember.get(this, 'initialCloudProvider');
      const selectedCloudProvider = Ember.get(this, 'selectedCloudProvider');
      const isAzureCurrentlySelected = selectedCloudProvider === 'azure';
      const isAzureInTreeSupported = Ember.get(this, 'isAzureInTreeSupported');
      const showChangedToExternal = Ember.get(this, 'showChangedToExternal');

      if (initialCloudProvider === 'amazonec2' && _semver.default.gte(_semver.default.coerce(kubernetesVersion), '1.27.0')) {
        Ember.set(this, 'unsupportedProviderSelected', true);
        Ember.set(this, 'selectedCloudProvider', 'external-aws');
        Ember.set(this, 'showChangedToAmazonExternal', true);
        this.constructConfig();
      } else if (initialCloudProvider === 'azure') {
        // Edit mode for Azure
        if (!isAzureInTreeSupported && isAzureCurrentlySelected) {
          Ember.set(this, 'unsupportedProviderSelected', true);
          Ember.set(this, 'selectedCloudProvider', 'external');
          Ember.set(this, 'showChangedToExternal', true);
          this.constructConfig();
        } else if (showChangedToExternal) {
          Ember.set(this, 'showChangedToExternal', false);
        }
      } else if (isAzureCurrentlySelected) {
        // Create mode for Azure
        if (!isAzureInTreeSupported) {
          Ember.set(this, 'selectedCloudProvider', 'external');
          Ember.set(this, 'showChangedToExternal', true);
          this.constructConfig();
        } else if (showChangedToExternal) {
          Ember.set(this, 'showChangedToExternal', false);
        }
      } else if (Ember.get(this, 'unsupportedProviderSelected')) {
        Ember.setProperties(this, {
          unsupportedProviderSelected: false,
          showChangedToAmazonExternal: false,
          selectedCloudProvider: Ember.get(this, 'initialCloudProvider'),
          configAnswers: Ember.get(this, 'initialConfigAnswers')
        });
      } else if (showChangedToExternal) {
        // Remove azure warning
        Ember.set(this, 'showChangedToExternal', false);
      }
    }),
    configAnswersDidChange: (0, _debounce.debouncedObserver)('mappedConfigAnswers.@each.{key,value}', function () {
      const mappedAnswers = Ember.get(this, 'mappedConfigAnswers');
      const selectedCloudProvider = Ember.get(this, 'selectedCloudProvider');
      const configAnswersOut = {};
      let pathForSet;

      switch (selectedCloudProvider) {
        case 'azure':
          pathForSet = AZURE_PATH;
          break;

        case 'amazonec2':
          pathForSet = AWS_PATH;
          break;

        default:
          pathForSet = GENERIC_PATH;
          break;
      }

      mappedAnswers.forEach(answer => {
        Ember.set(configAnswersOut, answer.key, answer.value);
      }); // Avoid setting cloud provider-specific configs in edit mode if the path doesn't exist (caused issues with Azure)

      if (!this.pathForSet && this.mode === 'edit') {
        return;
      }

      Ember.set(this, pathForSet, configAnswersOut);
    }),
    selectedCloudProviderOverrideAvailable: Ember.computed('applyClusterTemplate', 'clusterTemplateCreate', 'clusterTemplateRevision.{id,questions}', 'configName', 'selectedCloudProvider', 'isDestroying', 'isDestroyed', function () {
      let {
        clusterTemplateRevision,
        applyClusterTemplate
      } = this;

      if (applyClusterTemplate && clusterTemplateRevision) {
        if (clusterTemplateRevision.questions) {
          let found = clusterTemplateRevision.questions.filter(ctr => {
            return ctr.variable.includes('rancherKubernetesEngineConfig.cloudProvider');
          });

          if (found.length === 0 && this.selectedCloudProvider !== 'none') {
            Ember.set(this, 'selectedCloudProvider', 'none');
          }

          return found.length >= 1;
        } else {
          if (this.configName) {
            Ember.run.next(() => {
              if (this.isDestroyed || this.isDestroying) {
                return;
              }

              Ember.set(this, 'selectedCloudProvider', this.configName);
            });
          }
        }
      }

      return false;
    }),
    isCreateClusterOrClusterTemplate: Ember.computed('applyClusterTemplate', function () {
      const {
        applyClusterTemplate
      } = this;

      if (applyClusterTemplate) {
        return false;
      } else {
        return true;
      }
    }),
    isAzureInTreeSupported: Ember.computed('cluster.rancherKubernetesEngineConfig.kubernetesVersion', function () {
      const kubernetesVersion = Ember.get(this, 'cluster.rancherKubernetesEngineConfig.kubernetesVersion');
      return _semver.default.satisfies(_semver.default.coerce(kubernetesVersion), `>=${AZURE_IN_TREE_MINIMUM_VERSION} <${AZURE_IN_TREE_DEPRICATE_VERSION}`);
    }),
    mappedConfigAnswers: Ember.computed('configAnswers', function () {
      const configAnswers = Ember.get(this, 'configAnswers') || {};
      const out = [];
      Object.keys(configAnswers).forEach(answerKey => {
        out.push({
          key: answerKey,
          value: configAnswers[answerKey]
        });
      });
      return out;
    }),
    showVsphereHelperText: Ember.computed('selectedCloudProvider', 'driver', function () {
      const driver = Ember.get(this, 'driver');
      const selectedCloudProvider = Ember.get(this, 'selectedCloudProvider');
      return (driver === 'custom' || driver === 'vmwarevsphere') && selectedCloudProvider === 'external';
    }),
    harvesterCloudProviderDisabled: Ember.computed('cluster.name', 'model.{harvesterNodeTemplateId,nodeTemplates}', function () {
      const nodeTemplate = (Ember.get(this, 'model.nodeTemplates') || []).find(n => n.id === Ember.get(this, 'model.harvesterNodeTemplateId')) || {};
      const cloudCredentialId = Ember.get(nodeTemplate, 'cloudCredentialId');
      const cloudCredential = Ember.get(this, 'globalStore').getById('cloudCredential', cloudCredentialId) || {};
      const isExternalCredential = Ember.get(cloudCredential, 'harvestercredentialConfig.clusterType') === 'external';
      return !Ember.get(this, 'model.harvesterNodeTemplateId') || !Ember.get(this, 'cluster.name') || isExternalCredential;
    }),
    awsSupported: Ember.computed('cluster.rancherKubernetesEngineConfig.kubernetesVersion', function () {
      const kubernetesVersion = Ember.get(this, 'cluster.rancherKubernetesEngineConfig.kubernetesVersion');
      return _semver.default.lt(_semver.default.coerce(kubernetesVersion), '1.27.0');
    }),
    canEditProvider: Ember.computed('applyClusterTemplate', 'unsupportedProviderSelected', function () {
      return !!Ember.get(this, 'applyClusterTemplate') || Ember.get(this, 'unsupportedProviderSelected');
    }),

    checkDefaults(record) {
      Ember.get(this, 'azureDefaults').forEach(def => {
        if (Ember.isEmpty(record[def])) {
          Ember.set(record, def, null);
        }
      });
    },

    sortAzureFields(schema, answers) {
      const schemaFields = schema.getFieldNames();
      const resourceFields = Ember.get(schema, 'resourceFields');
      const descriptionInfo = _cloudProviderInfo.azure;
      const requiredFields = schemaFields.filter(item => Ember.get(descriptionInfo, `${item}.required`)).sort();
      const keysWithoutFields = schemaFields.filter(item => !requiredFields.includes(item)).sort();
      const prioritizedKeys = keysWithoutFields.unshiftObjects(requiredFields);
      const reorderedFields = {}; // Hack the schema to be required so validation will require them

      requiredFields.forEach(key => {
        schema.resourceFields[key].required = true;
      });
      prioritizedKeys.forEach(key => {
        let resourceField = Ember.get(resourceFields, key);

        if (answers && answers.hasOwnProperty(key)) {
          Ember.set(reorderedFields, key, Ember.get(answers, key));
        } else {
          if (Ember.get(resourceField, 'type') === 'password') {
            Ember.set(reorderedFields, key, null);
          }
        }
      });
      return reorderedFields;
    },

    constructConfig() {
      let nue = {};
      let selectedCloudProvider = Ember.get(this, 'selectedCloudProvider');
      let cluster = Ember.get(this, 'cluster');
      let config = Ember.get(cluster, 'rancherKubernetesEngineConfig') || Ember.set(cluster, 'rancherKubernetesEngineConfig', {});
      let harvesterCluster = {};
      let nodeTemplate;

      switch (selectedCloudProvider) {
        case 'azure':
          nue = Ember.get(this, 'globalStore').createRecord({
            type: 'azureCloudProvider'
          });
          delete nue.type;
          this.checkDefaults(nue);
          nue = this.sortAzureFields(this.globalStore.getById('schema', 'azurecloudprovider'), nue);
          Ember.set(config, 'cloudProvider', Ember.get(this, 'globalStore').createRecord({
            type: 'cloudProvider',
            name: 'azure',
            azureCloudProvider: nue
          }));
          Ember.setProperties(this, {
            hasBuiltIn: true,
            'cluster.rancherKubernetesEngineConfig': config,
            configAnswers: nue
          });
          break;

        case 'amazonec2':
          nue = Ember.get(this, 'globalStore').createRecord({
            type: 'awsCloudProvider'
          });
          Ember.set(config, 'cloudProvider', Ember.get(this, 'globalStore').createRecord({
            type: 'cloudProvider',
            name: 'aws',
            awsCloudProvider: nue
          }));
          Ember.set(this, 'configAnswers', nue);
          break;

        case 'harvester':
          harvesterCluster = this.getHarvesterCluster();
          Ember.set(config, 'cloudProvider', Ember.get(this, 'globalStore').createRecord({
            type: 'cloudProvider',
            name: 'harvester',
            harvesterCloudProvider: nue
          }));
          Ember.set(this, 'configAnswers', nue);
          nodeTemplate = (Ember.get(this, 'model.nodeTemplates') || []).find(n => n.id === Ember.get(this, 'model.harvesterNodeTemplateId'));
          Ember.get(this, 'globalStore').rawRequest({
            url: `/k8s/clusters/${harvesterCluster.id}/v1/harvester/kubeconfig`,
            method: 'POST',
            data: {
              csiClusterRoleName: 'harvesterhci.io:csi-driver',
              clusterRoleName: 'harvesterhci.io:cloudprovider',
              namespace: Ember.get(nodeTemplate, 'harvesterConfig.vmNamespace'),
              serviceAccountName: Ember.get(cluster, 'name')
            }
          }).then(obj => {
            Ember.set(config, 'cloudProvider.harvesterCloudProvider.cloudConfig', Ember.get(obj, 'body'));
          }).catch(err => {
            Ember.get(this, 'growl').fromError('Error getting kubeconfig file', err);
          });
          break;

        case 'external-aws':
          Ember.set(config, 'cloudProvider', Ember.get(this, 'globalStore').createRecord({
            type: 'cloudProvider',
            name: 'external-aws',
            useInstanceMetadataHostname: true
          }));
          break;

        case 'external':
          Ember.set(config, 'cloudProvider', Ember.get(this, 'globalStore').createRecord({
            type: 'cloudProvider',
            name: 'external'
          }));
          break;

        default:
          Ember.set(config, 'cloudProvider', Ember.get(this, 'globalStore').createRecord({
            type: 'cloudProvider',
            cloudConfig: nue
          }));
          Ember.set(this, 'configAnswers', nue);
          break;
      }
    },

    setCpFields() {
      throw new Error('setCpFields action is required!');
    },

    addOverride() {
      throw new Error('addOverride action is required!');
    },

    cloudProviderUserChange() {
      Ember.set(this, 'showChangedToAmazonExternal', false);
    },

    getHarvesterCluster() {
      const nodeTemplate = (Ember.get(this, 'model.nodeTemplates') || []).find(n => n.id === Ember.get(this, 'model.harvesterNodeTemplateId'));
      const cloudCredentialId = Ember.get(nodeTemplate, 'cloudCredentialId');
      const cloudCredential = Ember.get(this, 'globalStore').getById('cloudCredential', cloudCredentialId);
      const clusterId = Ember.get(cloudCredential, 'harvestercredentialConfig.clusterId');
      const harvesterCluster = Ember.get(this, 'globalStore').getById('cluster', clusterId);
      return harvesterCluster;
    }

  });

  _exports.default = _default;
});